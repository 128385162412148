import React from 'react'
import { Modal } from 'src/components/Modal'
import Popup from 'src/components/Popup'
import { Button } from '@interco/inter-ui/components/Button'
import { useDomReady } from '@interco/inter-ui'
import classnames from 'classnames'
import IconsSwitch from 'src/components/IconsSwitch/_index'
import * as S from './styles'

const TEXT_CONTENT = {
  success: {
    title: 'Planilha disponível pra você',
    body: 'Faça o download clicando no botão abaixo. Em breve, você também receberá o material por e-mail.',
    cta: 'Baixar agora',
    icon: 'status/ic_check_circle',
  },
  error: {
    title: 'Houve um erro por aqui',
    body: 'Não foi possível baixar a planilha. Experimente voltar dentro de alguns instantes.',
    cta: 'Entendi',
    icon: 'status/ic_close_circle',
  },
}

type BaixeGratisPopUpProps = {
  hasError: boolean;
  onCtaClick: () => void;
  isOpen: boolean;
  closePopup: () => void;
  openPopup: () => void;
}

const BaixeGratisPopup = ({ hasError, onCtaClick, isOpen, closePopup, openPopup }: BaixeGratisPopUpProps) => {
  const domReady = useDomReady()
  const selectedText = hasError ? TEXT_CONTENT.error : TEXT_CONTENT.success
  const PopUpContent = (
    <S.BaixeGratisPopUp>
      <div className={classnames('popup__icon', { 'popup__icon--success': !hasError, 'popup__icon--error': hasError })}>
        <IconsSwitch color='#fff' icon={selectedText.icon} width='24' height='24' lib='interco' />
      </div>
      <p className='popup__title'>{selectedText.title}</p>
      <p className='popup__body'>{selectedText.body}</p>
      <div className='popup__cta-box'>
        <a className='popup__cta-box__link'>
          <Button
            onClick={onCtaClick}
            fullWidth
            type='button'
          >
            {selectedText.cta}
          </Button>
        </a>
      </div>
    </S.BaixeGratisPopUp>
  )

  return domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={openPopup} locationToRender={document.body}>
      <Popup
        child={PopUpContent}
        closeModal={closePopup}
        pageName='Planilha de viagem'
      />
    </Modal>
  )
}

export default BaixeGratisPopup
