import { device } from "src/styles/breakpoints"
import { grayscale } from "src/styles/colors"
import { spacing16, spacing24, spacing32, spacing8 } from "src/styles/spacings"
import styled from "styled-components"

export const TextContent = styled.div`
  .title {
    margin-bottom: ${spacing8};
    text-align: center;
    font-size: 20px;
    line-height: 24px;
  }

  .subheading {
    font-family: 'Inter', Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    color: ${grayscale[400]};
    text-align: center;
    margin-bottom: ${spacing16};
  }

  .body-text {
    margin-bottom: ${spacing24};
  }

  @media ${device.tablet} {
    .title, .subheading {
      text-align: left;
    }

    .title {
      font-size: 28px;
      line-height: 32px;
    }

    .subheading {
      font-size: 18px;
      line-height: 22px;
    }

    .body-text {
      margin-bottom: ${spacing32};
      max-width: 271px;
    }
  }

  @media ${device.desktopLG} {
    .title {
      font-size: 40px;
      line-height: 44px;
      margin-bottom: ${spacing16};
    }

    .subheading {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: ${spacing32};
    }

    .body-text {
      margin-bottom: 43px;
      max-width: 378px;
    }
  }

  @media ${device.desktopXXL} {
    .title {
      font-size: 48px;
      line-height: 52px;
    }

    .subheading {
      font-size: 28px;
      line-height: 33px;
    }

    .body-text {
      margin-bottom: 52px;
      max-width: 434px;
    }
  }

  @media ${device.desktopXXLL} {
    .title {
      font-size: 56px;
      line-height: 62px;
    }

    .subheading {
      font-size: 40px;
      line-height: 44px;
    }

    .body-text {
      margin-bottom: 40px;
      max-width: 477px;
    }
  }
`

export const ImageContent = styled.div`
  margin-bottom: ${spacing24};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${device.tablet} {
    .img-complement-text {
      margin-bottom: ${spacing24};
    }
  }

  @media ${device.desktopXXL} {
    .img-complement-text {
      text-align: center;
    }
  }
`

export const CtaBox = styled.div`
  width: 100%;
  .button-link {
    text-decoration: none;
    height: fit-content;
  }

  @media ${device.tablet} {
    & {
      max-width: 215px;
    }
  }

  @media ${device.desktopLG} {
    & {
      max-width: 297px;
    }
  }

  @media ${device.desktopXXL} {
    & {
      max-width: 356px;
    }
  }
`
