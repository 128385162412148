import { device } from "src/styles/breakpoints"
import { grayscale, green, red } from "src/styles/colors"
import { spacing24, spacing32 } from "src/styles/spacings"
import styled from "styled-components"

export const BaixeGratisPopUp = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    margin: 0;
  }
  .popup__icon {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    margin: 0 auto ${spacing24};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    svg {
      fill: ${grayscale[500]};
    }

    &.popup__icon--success {
      background-color: ${green[400]};
      svg {
        fill: ${green[400]};
      }
    }

    &.popup__icon--error {
      background-color: ${red[500]};
      svg {
        fill: ${red.error};
      }
    }
  }
  .popup__title {
    font-family: 'Citrina', Helvetica, sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    margin-bottom: ${spacing24};
    color: ${grayscale[500]}
  }

  .popup__body {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: ${spacing32};
    color: ${grayscale[500]};
    text-align: center;
  }

  .popup__cta-box {
    width: 100%;
    max-width: 220px;
    margin: 0 auto;
    .popup__cta-box__link {
      text-decoration: none;
      height: fit-content;
    }
  }

  @media ${device.tablet} {
    & {
      max-width: 374px;
    }
  }
`
