import React from 'react'
import SectionComponent from 'src/components/SectionComponent'
import withCommonSectionProps from 'src/hoc/withCommonSectionProps'
import { PlanilhaViagensSectionsProps } from '../..'
import * as S from './styles'
import { H2PlanilhaDeViagem } from '../../_components/_H2PlanilhaDeViagens'
import IconsSwitch from 'src/components/IconsSwitch/_index'
import { orange } from 'src/styles/colors'
import { Button } from '@interco/inter-ui/components/Button'
import { InterP } from 'src/components/Text/BodyText'
import ScrollTo from 'src/components/ScrollTo'
import { WIDTH_MD } from 'src/styles/breakpoints'
import useWidth from 'src/hooks/window/useWidth'

type MaterialPraVoceProps = PlanilhaViagensSectionsProps

const TEXT_CONTENT = {
  heading: 'Este material é pra você que:',
  gridItem: [
    {
      icon: 'travel-mobility/ic_plane_departure', text: 'Vai fazer a primeira <strong>viagem internacional</strong>',
    },
    {
      icon: 'action-navigation/ic_clock', text: 'Não tem tempo pra <strong>planejar a viagem</strong>',
    },
    {
      icon: 'finance/ic_chart_pie', text: 'Busca uma <strong>planilha de gastos de viagem em excel</strong>',
    },
    {
      icon: 'home-objects/ic_beer', text: 'Deseja planejar uma <strong>viagem com um grupo de amigos</strong>',
    },
    {
      icon: 'travel-mobility/ic_briefcase', text: '<strong>Viaja com frequência</strong> e precisa se organizar',
    },
    {
      icon: 'finance/ic_wallet', text: 'Quer <strong>controlar</strong> seus gastos',
    },
    {
      icon: 'human/ic_users', text: 'Precisa organizar uma <strong>viagem com a família</strong>',
    },
    {
      icon: 'action-navigation/ic_heart', text: 'Quer fazer uma <strong>viagem romântica</strong> perfeita',
    },
  ],
  cta: 'Baixar planilha',
}

type GridItemProps = {
  icon: string;
  text: string;
}

const MaterialPraVoce = ({ id, dataLayerSection }: MaterialPraVoceProps) => {
  const width = useWidth()

  return (
    <SectionComponent
      id={id}
      minHeight={{ sm: '610px', md: '575px', lg: 'auto', xl: 'auto', xxl: 'auto' }}
    >
      <div className='col-12'>
        <S.TextContent>
          <H2PlanilhaDeViagem>{TEXT_CONTENT.heading}</H2PlanilhaDeViagem>
        </S.TextContent>
        <S.GridBox>
          {TEXT_CONTENT.gridItem.map((item: GridItemProps, index: number) => (
            <li key={'material-pra-voce' + index} className='grid-item'>
              <IconsSwitch lib='interco' width={width < WIDTH_MD ? '24' : '72'} height={width < WIDTH_MD ? '24' : '72'} color={orange.extra} icon={item.icon} />
              <InterP dangerouslySetInnerHTML={{ __html: item.text }} />
            </li>
          ))}
        </S.GridBox>
        <S.CtaBox>
          <ScrollTo
            to='#section-baixe-gratis'
            section={dataLayerSection}
            sectionName={TEXT_CONTENT.heading}
            elementName={TEXT_CONTENT.cta}
            className='button-link'
          >
            <Button fullWidth>
              {TEXT_CONTENT.cta}
            </Button>
          </ScrollTo>
        </S.CtaBox>
      </div>
    </SectionComponent>
  )
}

export default withCommonSectionProps(MaterialPraVoce)
